.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1099;
  background-color: #29162d34;
}

.container {
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  width: 250px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.image {
  width: 100px;
}

.imageContent {
  position: relative;
  height: 100px;
  display: flex;
  align-items: start;
}

.imageLoading {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  animation: image 2s linear infinite;
}

@keyframes image {
  0% {
    height: 100px;
  }
  100% {
    height: 0;
  }
}
