.header {
  top: 0px;
  position: sticky;
  width: 100%;
  
  z-index: 1001;

  display: flex;
  justify-content: space-between;
  padding-inline: 5px !important;
}

.actions {
  padding: 1rem;
  height: 100%;
  cursor: pointer;
}

.image {
  width: 100px;
  padding: 0.7rem 0.5rem;
}