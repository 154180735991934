.layout {
  height: 100vh;
  overflow: hidden;
}

.layout {
  :global {
    .ant-pro-sider-logo {
      a {
        width: 100%;
        height: 100%;
        img {
          width: 60px;
          height: 100%;
        }
      }

      h1 {
        display: none !important;
      }
    }

    .ant-pro-sider-logo-collapsed {
      img {
        width: 35px !important;
      }
    }
  }
}

.layout-internal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header-page {
  padding: 1rem 1rem 0 1rem;

  h3 {
    margin: 0;
  }

  nav {
    margin: 0;
    margin-top: 0.5rem;
  }
}

.content {
  padding: 1rem;
  overflow: auto;
  margin-bottom: 3.2rem;
}

.footer {
  text-align: center;
  height: 3.2rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid rgba(152, 188, 252, 0.16);
}
