:root {
  --primary-color: #1890FF;
  --secundary-color: #003e79;
  --purple-dark: #0b5ca7;

  --label-color: #003e79;
  
  --text-color: #003e79;

  --title-color: #003e79;
  --title-color-dark: #0b5ca7;

  --border-radius: 0.8rem;
  --border-color: rgba(0, 0, 0, 0.1);
  --box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --background-gradient: radial-gradient(circle at 50% -20.71%, #1890FF 0, #1071cc 25%, #075197 50%, #1c60a0 75%, #063f74 100%);

  --neutral-color-1: #ffffff;
  --neutral-color-2: #fafafa;
  --neutral-color-3: #f5f5f5;
  --neutral-color-4: #f0f0f0;
  --neutral-color-5: #d9d9d9;
  --neutral-color-6: #bfbfbf;
  --neutral-color-7: #8c8c8c;
  --neutral-color-8: #595959;
  --neutral-color-9: #434343;
  --neutral-color-10: #262626;
  --neutral-color-11: #1f1f1f;
  --neutral-color-12: #141414;
}
